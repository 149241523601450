<template>
  <footer class="footer min-w-1400 max-w-1400">
    <div class="footer-content">
      <p class="secondLine">
        Copyright ©KTC NANO COMPANY LIMITED
        <!-- <a  href="https://app.bot.or.th/BOTLicenseCheck/license/check?docid=23697/2567&token=6FA979DA-7CA5-41DE-8021-F24D664F1261" class="footer-span1" target="_blank">BOT License Check</a>
        <a href="https://www.ktcnanowallet.com/assets/PersonalLoanLicense.pdf" class="footer-span2" target="_blank">Personal Loan License</a> -->
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #116DFF;
  color: white;
  height: 60px;

}

.footer-content {
  font-size: 22px;
  text-align: center;
  margin: 0 auto;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.firstLine {
  width: 1270px;
  height: 20px;
  font-size: 18px;
  font-family: SF Pro, SF Pro;
  font-weight: 400;
  color: rgba(255, 255, 255,0.9); 
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.footer-content {
  font-size: 22px;
  text-align: center;
  margin: 0 auto;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}

.secondLine {
  width: 1098px;
  height: 20px;
  font-size: 19px;
  font-family: SF Pro-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}

.footer-span1 {
  display: inline-block; /* 将 <span> 设置为内联元素 */
  margin-left: 320px; /* 调整 <span> 之间的间距 */
  text-decoration: none; /* 去掉下划线 */
  color: white; /* 设置链接文字颜色 */
  font-size: 17px;

}
.footer-span2 {
  display: inline-block; /* 将 <span> 设置为内联元素 */
  margin-left: 30px; /* 调整 <span> 之间的间距 */
  text-decoration: none; /* 去掉下划线 */
  color: white; /* 设置链接文字颜色 */
  font-size: 17px;

}
</style>
