<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>

    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <!-- 第一个大标题 -->
          <div class="titleContainer1">
            <h2>เกี่ยวกับเรา</h2>
            <!-- 第一个大标题下的小标题 -->
            <div class="subtitles">
              <ul>
                <router-link to="/" tag="li" class="triangle">
                  <a>หน้าแรก</a>
                </router-link>
                <router-link to="/about" tag="li" class="triangle">
                  <a>เกี่ยวกับเรา</a>
                </router-link>
                <router-link to="/story" tag="li" class="triangle">
                  <a>เรื่องราวของเรา</a>
                </router-link>
              </ul>
            </div>
          </div>

          <!-- 第二个大标题 -->
          <div class="titleContainer2">
            <h2>นโยบาย</h2>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <router-link to="/protocol" tag="li" class="triangle">
                  <a>ข้อตกลงความเป็นส่วนตัว</a>
                </router-link>
              </ul>
            </div>
          </div>

          <!-- 第三个大标题 -->
          <div class="titleContainer3">
            <h2>ติดต่อเรา</h2>
            <!-- 第三个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul3">
                <li>KTC NANO COMPANY LIMITED</li>
                <li class="Li2">ที่อยู่: 591 Sukhumvit Road 10110 VADHANA</li>
                <li>โทรศัพท์: 95-901-4196</li>
                <li>อีเมล: contact@ktcnanowallet.com</li>
                <li>เว็บไซต์: www.ktcnanowallet.com</li>

                <li class="Li3">แอปจะเปิดตัวเร็วๆ นี้ โปรดคอยติดตาม</li>
                <li class="imageRow">
                  <div>
                    <img :src="importedImg5" :alt="altText" class="imgItem11" />
                  </div>
                  <div>
                    <img :src="importedImg6" :alt="altText" class="imgItem12" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/ktcnano/components/img/2_1.png";
import importedImg2 from "@/page/ktcnano/components/img/2_2.png";
import importedImg3 from "@/page/ktcnano/components/img/2_3.png";
import importedImg5 from "@/page/ktcnano/components/img/button.png";
import importedImg6 from "@/page/ktcnano/components/img/111.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
/* .imgBox2,
.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
} */
img {
  display: block;
}
.img1,
.img2,
.img3,
.img5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
/* .img2,
.img3 {
  margin-left: 45px;
  margin-right: 45px;
} */
.imgItem1,
.imgItem2,
.imgItem3,
.imgItem5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

/* .imgItem2 {
  max-width: 100%;
  margin-top: 40px;
} */

/* .imgItem3 {
  margin-top: -20px;
  max-width: 100%;
  margin-bottom: 80px;
} */
/* .imgItem5 {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
} */
.imgItem3 {
  margin-top: 20px;
  margin-bottom: 100px;
}


.imgBox5 {
  display: flex;
  justify-content: center;
  background: url("@/page/ktcnano/components/img/footer.png") no-repeat center
    center; /* 替换为你的图片路径 */
  background-size: cover;
  height: 500px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
}

.titleContainer1 {
  width: 200px;
  position: absolute;
  margin-top: 70px;
  margin-left: 200px;
}
.titleContainer2 {
  padding-left: 450px;
  margin-top: 70px;
  width: 400px;
}
.titleContainer3 {
  margin-left: 320px;
  margin-top: 70px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
h2 {
  color: #ffffff;
}
.Li2 {
  width: 900px;
}
.ul2 li {
  width: 220px;
}
.subtitles ul li.triangle::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px; /* 调整这里以改变三角形的大小 */
  border-color: transparent transparent transparent #ffffff; /* 设置三角形的颜色 */
  margin-right: 20px; /* 图标与文字之间的间距 */
}

.triangle a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}
.a2 {
  width: 400px;
}
li {
  font-size: 18px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 14px;
}
.imageRow {
  display: flex;
  margin-top: 20px;
}

.imageRow div {
  margin-right: 10px; /* 调整两个图片之间的间距 */
}
</style>
