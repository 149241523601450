<template>
  <nav class="navbar min-w-1400"   :class="{
      'black-bg':  $route.path === '/protocol',
    }">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/ceibamóvil/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">KTC NANO COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">หน้าแรก</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">เกี่ยวกับเรา</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/story' }"
          class="Li3"
        >
          <router-link to="/story" class="nav-link3">
            <span class="routeText3">เรื่องราวของเรา</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
       
            <a
            href="https://tawcw.ktcnanowallet.com/help/privacy_policy"
            class="nav-link4" target="_blank"
          >
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </a>
        </li>

        <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  /* position: absolute; */
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 80px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 80px;
  padding: 0 30px;
  background-color: #005FF7;
  /* background-color: blue; */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  width: 450px;
  height: 24px;
  font-size: 20px;
  font-family: SF Pro-Black Italic;
  font-weight: 600;
  color: #ffffff;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #ffffff;
  line-height: 29px;
  text-transform: uppercase;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  padding-left: 4px;
  padding-right: 4px;
}
.Li1 {
  width: 115px;
}
.Li2 {
  width: 115px;
}
.Li3 {
  width: 160px;
}
.Li4 {
  width: 230px;
}
.Li5 {
  width: 100px;
}
/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  color: #ffffff; /* 设置激活时的文本颜色 */
}
.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  border-bottom: 4px solid #ffffff; /* 初始时下划线可见 */
  border-radius: 20px;
  width: 100%; /* 调整下划线的长度 */
  transition: width 0s ease-in-out; /* 添加 transition 效果 */
  margin-top: 10px;
}
</style>